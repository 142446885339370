var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('AppLayout',{attrs:{"wrapperClass":"pageNotFound"}},[_c('Breadcrumbs'),_c('section',{staticClass:"page-not-found pt-8 pb-20"},[_c('b-container',[_c('div',[_c('h1',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("404_title")))]),_c('h3',{staticClass:"sub_title"},[_vm._v(" "+_vm._s(_vm.$t("404_sub_title"))+" ")]),_c('a',{staticClass:"btn btn-warning rounded px-md-80 my-25",attrs:{"href":"/"}},[_vm._v(" "+_vm._s(_vm.$t("back_to_homepage"))+" ")])]),_c('div',{staticClass:"mt-30 mb-40"},[_c('h5',{staticClass:"my-20"},[_vm._v(_vm._s(_vm.$t("page_longer_accessible")))]),_c('p',[_vm._v(" Lorem ipsum dolor sit amet consectetur. Ullamcorper eget viverra integer amet sit dolor tellus scelerisque sit. Nisl sodales iaculis semper pellentesque egestas. Lorem quis mauris tincidunt scelerisque quis id tortor vel. Lectus nisi nulla quis nulla nibh netus. Diam ac at tortor at mauris quis odio. Tempor nisl aenean bibendum feugiat penatibus. Sapien euismod pulvinar massa sed nisl urna ante. ")]),_c('p',[_vm._v(" Lorem ipsum dolor sit amet consectetur. Ullamcorper eget viverra integer amet sit dolor tellus scelerisque sit. Nisl sodales iaculis semper pellentesque egestas. Lorem quis mauris tincidunt scelerisque quis id tortor vel. Lectus nisi nulla quis nulla nibh netus. Diam ac at tortor at mauris quis odio. Tempor nisl aenean bibendum feugiat penatibus. Sapien euismod pulvinar massa sed nisl urna ante. ")])]),(_vm.bestSellerProduct.length > 0)?_c('div',{staticClass:"product-listing"},[_c('span',{staticClass:"title"},[_vm._v("Oder schauen Sie sich eines dieser Produkte an")]),_c('VueSlickCarousel',{attrs:{"dots":false,"arrows":true,"slidesToShow":4,"slidesToScroll":1,"infinite":true,"responsive":[
            {
              breakpoint: 1200,
              settings: {
                slidesToShow: 3 
              }
            },
            {
              breakpoint: 992,
              settings: {
                slidesToShow: 2 
              }
            },
            {
              breakpoint: 768,
              settings: {
                slidesToShow: 1
              }
            } ]}},_vm._l((_vm.bestSellerProduct.slice(0, 10)),function(product){return _c('ProductCard',{key:product.id,attrs:{"product":product}})}),1)],1):_vm._e(),(_vm.hrEnabled)?_c('Retargeted',{attrs:{"page":"notfound"}}):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }