<template>
  <AppLayout wrapperClass="pageNotFound">
    <Breadcrumbs />
    <section class="page-not-found pt-8 pb-20">
      <b-container>

        <div>
          <h1 class="title">{{ $t("404_title") }}</h1>
          <h3 class="sub_title">
            {{ $t("404_sub_title") }}
          </h3>
          <a href="/" class="btn btn-warning rounded px-md-80 my-25">
            {{ $t("back_to_homepage") }}
          </a>
        </div>

        <div class="mt-30 mb-40">
          <h5 class="my-20">{{ $t("page_longer_accessible") }}</h5>
          <p>
            Lorem ipsum dolor sit amet consectetur. Ullamcorper eget viverra integer amet sit dolor tellus scelerisque
            sit. Nisl sodales iaculis semper pellentesque egestas. Lorem quis mauris tincidunt scelerisque quis id
            tortor vel. Lectus nisi nulla quis nulla nibh netus. Diam ac at tortor at mauris quis odio. Tempor nisl
            aenean bibendum feugiat penatibus. Sapien euismod pulvinar massa sed nisl urna ante.
          </p>
          <p>
            Lorem ipsum dolor sit amet consectetur. Ullamcorper eget viverra integer amet sit dolor tellus scelerisque
            sit. Nisl sodales iaculis semper pellentesque egestas. Lorem quis mauris tincidunt scelerisque quis id
            tortor vel. Lectus nisi nulla quis nulla nibh netus. Diam ac at tortor at mauris quis odio. Tempor nisl
            aenean bibendum feugiat penatibus. Sapien euismod pulvinar massa sed nisl urna ante.
          </p>
        </div>

        <!-- Best Seller Products Carousel -->
        <div class="product-listing" v-if="bestSellerProduct.length > 0">
          <span class="title">Oder schauen Sie sich eines dieser Produkte an</span>
          <VueSlickCarousel :dots="false" :arrows="true" :slidesToShow="4" :slidesToScroll="1" :infinite="true"
            :responsive="[
              {
                breakpoint: 1200,
                settings: {
                  slidesToShow: 3 
                }
              },
              {
                breakpoint: 992,
                settings: {
                  slidesToShow: 2 
                }
              },
              {
                breakpoint: 768,
                settings: {
                  slidesToShow: 1
                }
              }
              // {
              //   breakpoint: 600,
              //   settings: {
              //     slidesToShow: 1
              //   }
              // }
            ]">
            <ProductCard v-for="product in bestSellerProduct.slice(0, 10)" :key="product.id" :product="product" />
          </VueSlickCarousel>
        </div>

        <!-- Retargeted Products (if Hello Retail is enabled) -->
        <Retargeted page="notfound" v-if="hrEnabled" />
      </b-container>
    </section>
  </AppLayout>
</template>


<script>
import AppLayout from "@/esf_kerkrade_vitanatura/core/components/AppLayout";
import Breadcrumbs from "@/esf_kerkrade_vitanatura/core/components/Breadcrumbs";
import Retargeted from "@/base/core/components/home/Retargeted.vue";
import ProductCard from "@/esf_kerkrade_vitanatura/core/components/ProductCard.vue";
import VueSlickCarousel from "vue-slick-carousel";
import { isServer } from "@storefront/core/helpers";

import config from "@config";

export default {
  name: "PageNotFound",
  components: {
    AppLayout,
    Breadcrumbs,
    Retargeted,
    ProductCard,
    VueSlickCarousel,
  },
  data() {
    return {
      hrEnabled: config.helloRetail && config.helloRetail.enabled,
    
    };
  },
  computed: {
    bestSellerProduct() {
      return this.$store.getters["product_local/getBestSellerProducts"];
    },
  },
  metaInfo() {
    const title = this.$t("404_line_1");
    return {
      meta: [
        { name: "title", content: title },
        { name: "keywords", content: title },
        { name: "description", content: title },
        {
          name: "robots",
          content: "nofollow,noindex,noarchive,nositelinkssearchbox,nosnippet,noimageindex",
        },
      ],
      title,
    };
  },
  async mounted() {
    // Load retargeted products if HelloRetail is enabled
    if (!isServer && this.hrEnabled) {
      const helloRetailId = this.$cookies.get("hello_retail_id");
      await this.loadRetargetedProducts(helloRetailId);
    }

    // Set breadcrumb for 404
    this.$store.commit("breadcrumbs/set", {
      current: "404",
      routes: [],
    });

  },
  methods: {
    async loadRetargetedProducts(helloRetailId) {
      try {
        await this.$store.dispatch("home/loadRetargetedProducts", {
          hello_retail_id: helloRetailId,
          page: "notfound",
        });
      } catch (error) {
        console.error("Error loading retargeted products:", error);
      }
    },



  },
};
</script>
